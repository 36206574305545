@import "../scss/utils";

.container {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bg-color;
  box-sizing: border-box;
}

.content {
  padding: 40px;
  max-width: 900px;
  box-sizing: border-box;
}

.section_wrap {
  & + & {
    margin-top: 20px;
  }
}

.section {
  margin-top: 20px;
}
