.project_iframe {
  width: 100vw;
  height: 100vh;
}

.project_card {
  background: #fff;
  border: 1px solid #dde4ed;
  border-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.05));
  transition: 0.1s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }
}

.thumbnail_wrap {
  width: 100%;
  aspect-ratio: 1/0.9;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  padding: 10px;
}

.title {
  font-size: 16px;
}
