@import "../../scss/utils";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 100;
  border-bottom: 1px solid #eaecef;
}

.inner {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;

  color: $text-color;
}

.nav_area {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
